import Axios from "../Config/shop"
import {addressOrderNumType, bikeDisPathSucType, HeaderType} from "@/Api/ShopAdmin/interfaceType";


// 配送信息
export function disPathSuc( headers:HeaderType ):Promise<any> {
    return (
        Axios({
            url:"/app/order/sendInfo",
            method:"POST",
            headers,
            data:{}
        })
    )
}

// 骑手信息 body:bikeDisPathSucType
export function bikeDisPathSuc( headers:HeaderType,body = {} ):Promise<any> {
    return (
        Axios({
            url:"/app/order/dayOrderSendInfo",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 商户信息
export function tenantSuc( headers:HeaderType ):Promise<any> {
    return (
        Axios({
            url:"/app/order/dayAreaSendOrder",
            method:"POST",
            headers,
            data:{}
        })
    )
}

// 地址订单量
export function addressOrderNum( headers:HeaderType,body:addressOrderNumType ):Promise<any> {
    return (
        Axios({
            url:"/app/order/dayAreaOrderInfo",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 地址时长
export function addressOrderTime( headers:HeaderType,body:addressOrderNumType ):Promise<any> {
    return (
        Axios({
            url:"/app/order/dayStoreOrderTimeInfo",
            method:"POST",
            headers,
            data:body
        })
    )
}
