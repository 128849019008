
















import {Vue, Component} from "vue-property-decorator";
import DeliverySucHeadType from "./indexType";
import ScrollViewX from "@/components/ShareComponent/ScrollViewX.vue"
import { navList } from '../../Model/index';

@Component({name: "DeliverySucHead",components:{ ScrollViewX }})
export default class DeliverySucHead extends Vue implements DeliverySucHeadType{
    navList = navList
}
