

















import {Vue, Component, Watch} from "vue-property-decorator";
import DeliverySucListType from "./indexType";
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import DeliverySucItem from "@/views/Dispatching/DeliverySuc/components/Item/index.vue";
import {getDisPath} from "@/views/Dispatching/DeliverySuc/Server";
import {DeliverySucStore} from "@/views/Dispatching/DeliverySuc/Store";

@Component({name: "DeliverySucList",components:{ PullDownUpList,ListBottom,DeliverySucItem }})
export default class DeliverySucList extends Vue implements DeliverySucListType{
    PullDown = false
    StopUp = false
    UpDownBool = false
    List: any[] = [];

    mounted(){
        this.handleUpData(true)
    }

    handlePullDown(): void {
        this.PullDown = true
        this.handleUpData(true)
    }

    handleUpDown(): void {
        if ( this.StopUp )return
        this.handleUpData()
    }

    handleUpData(bool?: boolean): void {
        getDisPath().then(res=>{
            this.PullDown = false
            this.UpDownBool = false
            this.StopUp = true
            if ( bool )
                DeliverySucStore.SetDeliverySucList( res )
            else
                DeliverySucStore.SetDeliverySucList( DeliverySucStore.getDeliverySucList.concat(res) )
        })
    }

    get getList(){ return DeliverySucStore.getDeliverySucList }
    @Watch("getList")
    changeList(newVal:any[]){
        this.List = newVal
    }
}
