











import {Vue, Component, Watch} from "vue-property-decorator";
import DeliverySucType from "./DeliverySuc";
import ZoomPage from "@/impView/PageSafety";
import HeadTop from "@/components/ShareComponent/HeadTop.vue";
import DeliverySucHead from "@/views/Dispatching/DeliverySuc/components/Head/index.vue";
import DeliverySucList from "@/views/Dispatching/DeliverySuc/components/List/index.vue";
import {getDisPath} from "@/views/Dispatching/DeliverySuc/Server";
import {DeliverySucStore} from "@/views/Dispatching/DeliverySuc/Store";

@Component({name: "DeliverySuc",components:{ HeadTop,DeliverySucHead,DeliverySucList }})
export default class DeliverySuc extends ZoomPage implements DeliverySucType{

    mounted(){
        this.loadingShow = false
    }

}
