













import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import DeliverySucItemType from "./indexType";
import { computedTime } from "@/util/computedTime";

@Component({name: "DeliverySucItem"})
export default class DeliverySucItem extends Vue implements DeliverySucItemType{
    rightIcon = require("@/assets/icon/Basics/Right.png")
    List:any[] = []
    strData = ""

    mounted(){
        this.initData()
    }

    initData(){
        if ( JSON.stringify( this.getData ) == "{}" )return;
        this.List = [
            this.getIndex + 1,
            this.getData.name || '',
            this.getData.sendTime && computedTime(this.getData.sendTime) || '无', // moment().format("")
            this.getData.pickUp || 0,
            this.getData.arrive || 0,
            this.getData.finish || 0
        ]
        this.strData = JSON.stringify( this.getData )
    }

    get getColor(){
        if ( !this.getData )return "#6d6d6d"
        switch ( this.getData && Number(this.getData.workStatus) ) {
            case 1:
                return "#2bae68"
            case 2:
                return "#f45c57"
            default:
                return "#6d6d6d"
        }
    }

    @Prop(Object)
    data!:any
    get getData(){ return this.data }
    @Watch("getData")
    changeData(newVal:any){
        this.initData()
    }

    @Prop(Number)
    index!:number
    get getIndex(){ return this.index || 0 }
}
